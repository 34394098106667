import store from '@/store';
import { ASSIGNMENT_STATUS } from '@/shared/common';

/**
 *
 * @param {*} assignment
 * @returns an object with assignment label, color, & message
 */
const getAssignmentStatusObj = (assignment) => {
  const driverConfig = store.getters['config/driverConfig'];
  if (driverConfig?.driverAccept || driverConfig?.driverDecline) {
    switch (assignment.status) {
      case ASSIGNMENT_STATUS.NONE:
        return { display: 'No Driver Assigned', color: 'orange darken-3' };
      case ASSIGNMENT_STATUS.PENDING:
        return { display: 'Pending', color: 'blue darken-2' };
      case ASSIGNMENT_STATUS.SELF_ACCEPTED:
        return { display: 'Self Accepted', color: 'green darken-1' };
      case ASSIGNMENT_STATUS.ADMIN_ACCEPTED:
        return { display: 'Admin Accepted', color: 'green darken-1' };
      case ASSIGNMENT_STATUS.SELF_DECLINED:
        return { display: 'Self Declined', color: 'red accent-2', message: assignment.notes };
      case ASSIGNMENT_STATUS.ADMIN_DECLINED:
        return { display: 'Admin Declined', color: 'red accent-2', message: assignment.notes };
    }
  }
  return { display: 'Assigned', color: 'green darken-1' };
};

export { getAssignmentStatusObj };
