var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    [
      _c(
        "v-container",
        {
          staticClass: "pa-0",
          class: `${this.status.color.split(" ")[0]}-border `,
          attrs: { fluid: "" },
        },
        [
          _c(
            "v-row",
            {
              staticClass: "ma-0 pa-0",
              class: this.expand
                ? `${this.status.color} white--text ${
                    this.status.color.split(" ")[0]
                  }-border-bottom`
                : "black--text",
            },
            [
              _c(
                "v-col",
                {
                  staticClass:
                    "text-center clickable ma-0 no-space d-flex flex-column justify-center",
                  class: _vm.numClass,
                  attrs: { cols: "auto" },
                  on: {
                    click: function ($event) {
                      return _vm.$router.push(
                        _vm.returnToDashboard
                          ? `/trip-request/${_vm.assignment.tripRequestId}?assignment=true&dashboard=true`
                          : `/trip-request/${_vm.assignment.tripRequestId}?assignment=true`
                      )
                    },
                  },
                },
                [
                  _vm.assignment.batchId
                    ? _c(
                        "div",
                        { staticClass: "text-subtitle-1 text-center" },
                        [_vm._v("Batch #" + _vm._s(_vm.assignment.batchId))]
                      )
                    : _vm._e(),
                  _c(
                    "div",
                    { staticClass: "text-h5 text-center font-weight-bold" },
                    [_vm._v("#" + _vm._s(_vm.assignment.tripRequestId))]
                  ),
                  _c(
                    "div",
                    { staticClass: "icon-wrapper" },
                    [
                      _vm.displayIcons.mileageSubmitted ||
                      _vm.displayIcons.hoursSubmitted
                        ? _c(
                            "v-tooltip",
                            {
                              attrs: { bottom: "" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function ({ on, attrs }) {
                                      return [
                                        _c(
                                          "v-icon",
                                          _vm._g(
                                            _vm._b(
                                              {
                                                class: _vm.expand
                                                  ? `${
                                                      _vm.status.color.split(
                                                        " "
                                                      )[0]
                                                    }--text text--${
                                                      _vm.status.color.split(
                                                        " "
                                                      )[1]
                                                    } `
                                                  : `white--text `,
                                              },
                                              "v-icon",
                                              attrs,
                                              false
                                            ),
                                            on
                                          ),
                                          [_vm._v(" mdi-clipboard-edit ")]
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                2144302435
                              ),
                            },
                            [
                              _c("span", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.displayIcons.hoursSubmitted &&
                                        _vm.displayIcons.mileageSubmitted
                                        ? "Hours & Mileage"
                                        : _vm.displayIcons.mileageSubmitted
                                        ? "Mileage"
                                        : "Hours"
                                    ) +
                                    " submitted "
                                ),
                              ]),
                            ]
                          )
                        : _vm._e(),
                      _vm.assignment.leg > 0
                        ? _c(
                            "v-tooltip",
                            {
                              attrs: { bottom: "" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function ({ on, attrs }) {
                                      return [
                                        _c(
                                          "v-icon",
                                          _vm._g(
                                            _vm._b(
                                              {
                                                class: _vm.expand
                                                  ? `${
                                                      _vm.status.color.split(
                                                        " "
                                                      )[0]
                                                    }--text text--${
                                                      _vm.status.color.split(
                                                        " "
                                                      )[1]
                                                    } `
                                                  : `white--text `,
                                              },
                                              "v-icon",
                                              attrs,
                                              false
                                            ),
                                            on
                                          ),
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.assignment.leg == 1
                                                  ? "mdi-arrow-right-bold-circle"
                                                  : "mdi-arrow-left-bold-circle"
                                              ) + " "
                                            ),
                                          ]
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                2264438505
                              ),
                            },
                            [
                              _c("span", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      `Split Trip ${
                                        _vm.assignment.leg == 1
                                          ? "Drop-Off"
                                          : "Return"
                                      } Leg`
                                    ) +
                                    " "
                                ),
                              ]),
                            ]
                          )
                        : _vm._e(),
                      _vm.assignment.vehicleNotes
                        ? _c(
                            "v-tooltip",
                            {
                              attrs: { bottom: "" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function ({ on, attrs }) {
                                      return [
                                        _c(
                                          "v-icon",
                                          _vm._g(
                                            _vm._b(
                                              {
                                                class: _vm.expand
                                                  ? `${
                                                      _vm.status.color.split(
                                                        " "
                                                      )[0]
                                                    }--text text--${
                                                      _vm.status.color.split(
                                                        " "
                                                      )[1]
                                                    } `
                                                  : `white--text `,
                                              },
                                              "v-icon",
                                              attrs,
                                              false
                                            ),
                                            on
                                          ),
                                          [_vm._v("mdi-forum-outline ")]
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                3021550512
                              ),
                            },
                            [_c("span", [_vm._v(" With vehicle comments ")])]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ]
              ),
              _vm.expand
                ? [
                    _c(
                      "v-col",
                      {
                        staticClass: "pa-0 ml-2",
                        attrs: { cols: "2", md: "2" },
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "text-overline font-weight-bold" },
                          [_vm._v("Assignment")]
                        ),
                        _c(
                          "div",
                          { staticClass: "text-subtitle-1" },
                          [
                            _c(
                              "v-icon",
                              {
                                class: _vm.expand
                                  ? `white--text `
                                  : `${
                                      _vm.status.color.split(" ")[0]
                                    }--text text--${
                                      _vm.status.color.split(" ")[1]
                                    } `,
                              },
                              [_vm._v("mdi-bus")]
                            ),
                            _vm._v(
                              _vm._s(_vm.vehicle || "None assigned") + " "
                            ),
                          ],
                          1
                        ),
                        _vm.getVehicleLocation(_vm.assignment.vehicleId)
                          ? _c(
                              "div",
                              { staticClass: "text-subtitle-1 py-0 my-0" },
                              [
                                _c(
                                  "v-icon",
                                  {
                                    class: _vm.expand
                                      ? `white--text `
                                      : `${
                                          _vm.status.color.split(" ")[0]
                                        }--text text--${
                                          _vm.status.color.split(" ")[1]
                                        } `,
                                  },
                                  [_vm._v("mdi-map-marker-radius")]
                                ),
                                _vm._v(
                                  _vm._s(
                                    _vm.getVehicleLocation(
                                      _vm.assignment.vehicleId
                                    )
                                  ) + " "
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _c(
                          "div",
                          { staticClass: "text-subtitle-1" },
                          [
                            _c(
                              "v-icon",
                              {
                                class: _vm.expand
                                  ? `white--text `
                                  : `${
                                      _vm.status.color.split(" ")[0]
                                    }--text text--${
                                      _vm.status.color.split(" ")[1]
                                    } `,
                              },
                              [_vm._v("mdi-account")]
                            ),
                            _vm._v(_vm._s(_vm.driver || "None assigned") + " "),
                          ],
                          1
                        ),
                      ]
                    ),
                    _c(
                      "v-col",
                      { staticClass: "pa-0 pl-4", attrs: { cols: "2" } },
                      [
                        _c(
                          "div",
                          { staticClass: "text-overline font-weight-bold" },
                          [_vm._v("Vehicle Owner")]
                        ),
                        _c(
                          "v-tooltip",
                          {
                            attrs: {
                              bottom: "",
                              disabled: !_vm.vehicleOwner.email,
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "activator",
                                  fn: function ({ on, attrs }) {
                                    return [
                                      _c(
                                        "div",
                                        _vm._g(
                                          _vm._b(
                                            { staticClass: "text-subtitle-1" },
                                            "div",
                                            attrs,
                                            false
                                          ),
                                          on
                                        ),
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(_vm.vehicleOwner.display) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              3691511931
                            ),
                          },
                          [
                            _c("span", {
                              domProps: {
                                innerHTML: _vm._s(_vm.vehicleOwner.email),
                              },
                            }),
                          ]
                        ),
                      ],
                      1
                    ),
                    _c("v-col", { staticClass: "pa-0", attrs: { cols: "2" } }, [
                      _c(
                        "div",
                        { staticClass: "text-overline font-weight-bold" },
                        [_vm._v("Trip Duration")]
                      ),
                      _c("div", { staticClass: "text-subtitle-1" }, [
                        _vm._v(_vm._s(_vm.totalTime)),
                      ]),
                    ]),
                    _c("v-col", { staticClass: "pa-0", attrs: { cols: "2" } }, [
                      _c(
                        "div",
                        { staticClass: "text-overline font-weight-bold" },
                        [_vm._v("Trip Mileage")]
                      ),
                      _c("div", { staticClass: "text-subtitle-1" }, [
                        _vm._v(_vm._s(_vm.totalDistance)),
                      ]),
                    ]),
                    _c(
                      "v-col",
                      { staticClass: "pa-0", attrs: { cols: "4", md: "2" } },
                      [
                        _c(
                          "div",
                          { staticClass: "text-overline font-weight-bold" },
                          [_vm._v("Location / Destination")]
                        ),
                        _c("div", { staticClass: "text-subtitle-1" }, [
                          _vm._v(_vm._s(_vm.locationName)),
                        ]),
                        _c("div", { staticClass: "text-subtitle-1" }, [
                          _vm._v(_vm._s(_vm.destinationName)),
                        ]),
                      ]
                    ),
                  ]
                : [
                    _c(
                      "v-col",
                      {
                        staticClass: "pa-0 ml-2",
                        attrs: { cols: "12", md: "2" },
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "text-overline font-weight-bold" },
                          [_vm._v("Assignment")]
                        ),
                        _c(
                          "div",
                          { staticClass: "text-subtitle-1 py-0 my-0" },
                          [
                            _c(
                              "v-icon",
                              {
                                class: _vm.expand
                                  ? `white--text `
                                  : `${
                                      _vm.status.color.split(" ")[0]
                                    }--text text--${
                                      _vm.status.color.split(" ")[1]
                                    } `,
                              },
                              [_vm._v("mdi-bus")]
                            ),
                            _vm._v(
                              _vm._s(_vm.vehicle || "None assigned") + " "
                            ),
                          ],
                          1
                        ),
                        _vm.getVehicleLocation(_vm.assignment.vehicleId)
                          ? _c(
                              "div",
                              { staticClass: "text-subtitle-1 py-0 my-0" },
                              [
                                _c(
                                  "v-icon",
                                  {
                                    class: _vm.expand
                                      ? `white--text `
                                      : `${
                                          _vm.status.color.split(" ")[0]
                                        }--text text--${
                                          _vm.status.color.split(" ")[1]
                                        } `,
                                  },
                                  [_vm._v("mdi-map-marker-radius")]
                                ),
                                _vm._v(
                                  _vm._s(
                                    _vm.getVehicleLocation(
                                      _vm.assignment.vehicleId
                                    )
                                  ) + " "
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _c(
                          "div",
                          { staticClass: "text-subtitle-1" },
                          [
                            _c(
                              "v-icon",
                              {
                                class: _vm.expand
                                  ? `white--text `
                                  : `${
                                      _vm.status.color.split(" ")[0]
                                    }--text text--${
                                      _vm.status.color.split(" ")[1]
                                    } `,
                              },
                              [_vm._v("mdi-account")]
                            ),
                            _vm._v(_vm._s(_vm.driver || "None assigned") + " "),
                          ],
                          1
                        ),
                      ]
                    ),
                    _c(
                      "v-col",
                      { staticClass: "pa-0", attrs: { cols: "12", md: "1" } },
                      [
                        _c(
                          "div",
                          { staticClass: "text-overline font-weight-bold" },
                          [_vm._v("Vehicle Owner")]
                        ),
                        _c(
                          "v-tooltip",
                          {
                            attrs: {
                              bottom: "",
                              disabled: !_vm.vehicleOwner.email,
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "activator",
                                fn: function ({ on, attrs }) {
                                  return [
                                    _c(
                                      "div",
                                      _vm._g(
                                        _vm._b(
                                          { staticClass: "text-subtitle-1" },
                                          "div",
                                          attrs,
                                          false
                                        ),
                                        on
                                      ),
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(_vm.vehicleOwner.display) +
                                            " "
                                        ),
                                      ]
                                    ),
                                  ]
                                },
                              },
                            ]),
                          },
                          [
                            _c("span", {
                              domProps: {
                                innerHTML: _vm._s(_vm.vehicleOwner.email),
                              },
                            }),
                          ]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "v-col",
                      { staticClass: "pa-0", attrs: { cols: "12", md: "2" } },
                      [
                        _c(
                          "div",
                          { staticClass: "text-overline font-weight-bold" },
                          [_vm._v("Leave / Return")]
                        ),
                        _c("div", { staticClass: "text-subtitle-1" }, [
                          _vm._v(
                            _vm._s(_vm.leaveDate) +
                              " @ " +
                              _vm._s(_vm.leaveTime)
                          ),
                        ]),
                        _c("div", { staticClass: "text-subtitle-1" }, [
                          _vm._v(
                            _vm._s(_vm.returnDate) +
                              " @ " +
                              _vm._s(_vm.returnTime)
                          ),
                        ]),
                      ]
                    ),
                    _c("v-spacer"),
                    _c(
                      "v-col",
                      { staticClass: "pa-0", attrs: { cols: "12", md: "1" } },
                      [
                        _c(
                          "div",
                          { staticClass: "text-overline font-weight-bold" },
                          [_vm._v("Duration / Mileage")]
                        ),
                        _c("div", { staticClass: "text-subtitle-1" }, [
                          _vm._v(_vm._s(_vm.totalTime)),
                        ]),
                        _c("div", { staticClass: "text-subtitle-1" }, [
                          _vm._v(_vm._s(_vm.totalDistance)),
                        ]),
                      ]
                    ),
                    _c("v-spacer"),
                    _c(
                      "v-col",
                      { staticClass: "pa-0", attrs: { cols: "12", md: "2" } },
                      [
                        _c(
                          "div",
                          { staticClass: "text-overline font-weight-bold" },
                          [_vm._v("Location / Destination")]
                        ),
                        _c("div", { staticClass: "text-subtitle-1" }, [
                          _vm._v(_vm._s(_vm.locationName)),
                        ]),
                        _c("div", { staticClass: "text-subtitle-1" }, [
                          _vm._v(_vm._s(_vm.destinationName)),
                        ]),
                      ]
                    ),
                    _c("v-spacer"),
                    _c(
                      "v-col",
                      { staticClass: "pa-0", attrs: { cols: "12", md: "1" } },
                      [
                        _c(
                          "div",
                          { staticClass: "text-overline font-weight-bold" },
                          [_vm._v("Requester")]
                        ),
                        _c("div", { staticClass: "text-subtitle-1" }, [
                          _vm._v(_vm._s(_vm.requesterName)),
                        ]),
                      ]
                    ),
                    _c("v-spacer"),
                    _vm.driverConfig.driverAccept ||
                    _vm.driverConfig.driverDecline
                      ? _c(
                          "v-col",
                          {
                            staticClass: "pa-0",
                            attrs: { cols: "12", md: "2" },
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "text-overline font-weight-bold" },
                              [_vm._v("Driver Status")]
                            ),
                            _c(
                              "v-tooltip",
                              {
                                attrs: {
                                  bottom: "",
                                  disabled: !_vm.status.message,
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "activator",
                                      fn: function ({ on, attrs }) {
                                        return [
                                          _c(
                                            "div",
                                            _vm._g(
                                              _vm._b(
                                                {
                                                  staticClass:
                                                    "text-subtitle-1",
                                                },
                                                "div",
                                                attrs,
                                                false
                                              ),
                                              on
                                            ),
                                            [
                                              _vm.assignment.status ==
                                                _vm.ASSIGNMENT_STATUS
                                                  .SELF_ACCEPTED ||
                                              _vm.assignment.status ==
                                                _vm.ASSIGNMENT_STATUS
                                                  .ADMIN_ACCEPTED
                                                ? _c(
                                                    "v-icon",
                                                    {
                                                      attrs: {
                                                        color: _vm.expand
                                                          ? "white"
                                                          : "success",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        " mdi-check-circle "
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                              _vm._v(
                                                " " +
                                                  _vm._s(_vm.status.display) +
                                                  " "
                                              ),
                                            ],
                                            1
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  2482271500
                                ),
                              },
                              [_c("span", [_vm._v(_vm._s(_vm.status.message))])]
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                  ],
              _c("v-spacer"),
              _c(
                "aside",
                { staticClass: "expand-button" },
                [
                  _c(
                    "v-btn",
                    { attrs: { icon: "" }, on: { click: _vm.handleExpand } },
                    [
                      _c("v-icon", { class: _vm.expand ? "white--text" : "" }, [
                        _vm._v(
                          _vm._s(
                            _vm.expand ? "mdi-chevron-up" : "mdi-chevron-down"
                          )
                        ),
                      ]),
                    ],
                    1
                  ),
                  !_vm.hideQuickActions
                    ? _c(
                        "v-menu",
                        {
                          attrs: { bottom: "", left: "" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function ({ on, attrs }) {
                                  return [
                                    _c(
                                      "v-btn",
                                      _vm._g(
                                        _vm._b(
                                          { attrs: { icon: "" } },
                                          "v-btn",
                                          attrs,
                                          false
                                        ),
                                        on
                                      ),
                                      [
                                        _c(
                                          "v-icon",
                                          {
                                            class: _vm.expand
                                              ? "white--text"
                                              : "",
                                          },
                                          [_vm._v("mdi-dots-vertical")]
                                        ),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            2881235761
                          ),
                        },
                        [
                          _c(
                            "v-list",
                            [
                              _vm.showSendDriverEmail
                                ? _c(
                                    "v-list-item",
                                    {
                                      on: {
                                        click: function ($event) {
                                          return _vm.sendDriverEmail()
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "v-list-item-icon",
                                        [
                                          _c(
                                            "v-icon",
                                            { attrs: { color: "primary" } },
                                            [_vm._v("mdi-email")]
                                          ),
                                        ],
                                        1
                                      ),
                                      _c("v-list-item-title", [
                                        _vm._v("Send Driver Notification"),
                                      ]),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.hasTripTicketTemplate
                                ? _c(
                                    "v-list-item",
                                    {
                                      on: {
                                        click: function ($event) {
                                          return _vm.download()
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "v-list-item-icon",
                                        [
                                          _c(
                                            "v-icon",
                                            { attrs: { color: "primary" } },
                                            [_vm._v("mdi-download")]
                                          ),
                                        ],
                                        1
                                      ),
                                      _c("v-list-item-title", [
                                        _vm._v("Trip Ticket"),
                                      ]),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.canEnterMileage
                                ? _c(
                                    "v-list-item",
                                    {
                                      on: {
                                        click: function ($event) {
                                          return _vm.quickEnter()
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "v-list-item-icon",
                                        [
                                          _c(
                                            "v-icon",
                                            { attrs: { color: "primary" } },
                                            [_vm._v("mdi-pencil")]
                                          ),
                                        ],
                                        1
                                      ),
                                      _c("v-list-item-title", [
                                        _vm._v("Enter Mileage/Time"),
                                      ]),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.driverConfig.driverAccept &&
                              _vm.assignment.status === 1 &&
                              _vm.isAdmin
                                ? _c(
                                    "v-list-item",
                                    {
                                      on: {
                                        click: function ($event) {
                                          return _vm.adminAccept()
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "v-list-item-icon",
                                        [
                                          _c(
                                            "v-icon",
                                            { attrs: { color: "primary" } },
                                            [_vm._v("mdi-check-circle-outline")]
                                          ),
                                        ],
                                        1
                                      ),
                                      _c("v-list-item-title", [
                                        _vm._v("Accept"),
                                      ]),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.driverConfig.driverDecline &&
                              _vm.assignment.status === 1 &&
                              _vm.isAdmin
                                ? _c(
                                    "v-list-item",
                                    {
                                      on: {
                                        click: function ($event) {
                                          return _vm.$emit(
                                            "adminDecline",
                                            _vm.assignment.id
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "v-list-item-icon",
                                        [
                                          _c(
                                            "v-icon",
                                            { attrs: { color: "primary" } },
                                            [_vm._v("mdi-cancel")]
                                          ),
                                        ],
                                        1
                                      ),
                                      _c("v-list-item-title", [
                                        _vm._v("Decline"),
                                      ]),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            2
          ),
          _vm.expand ? _c("v-divider") : _vm._e(),
          _c(
            "v-expand-transition",
            [
              _c(
                "v-row",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.expand,
                      expression: "expand",
                    },
                  ],
                  staticClass: "ma-0",
                },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12", md: "4" } },
                    [
                      _c(
                        "v-row",
                        { staticClass: "px-4" },
                        [
                          _c("v-col", { attrs: { cols: "12", md: "4" } }, [
                            _c("div", { staticClass: "text-overline" }, [
                              _vm._v("Leave"),
                            ]),
                            _c(
                              "div",
                              { staticClass: "text-h5 font-weight-bold" },
                              [_vm._v(_vm._s(_vm.leaveDate))]
                            ),
                            _c("div", { staticClass: "text-subtitle-1" }, [
                              _vm._v(_vm._s(_vm.leaveTime)),
                            ]),
                            _c("div", { staticClass: "text-overline" }, [
                              _vm._v("Return"),
                            ]),
                            _c(
                              "div",
                              { staticClass: "text-h5 font-weight-bold" },
                              [_vm._v(_vm._s(_vm.returnDate))]
                            ),
                            _c("div", { staticClass: "text-subtitle-1" }, [
                              _vm._v(_vm._s(_vm.returnTime)),
                            ]),
                          ]),
                          _c(
                            "v-col",
                            { attrs: { cols: "12", md: "8" } },
                            [
                              _c("div", { staticClass: "text-overline" }, [
                                _vm._v("Route"),
                              ]),
                              _c(
                                "v-list",
                                { staticClass: "py-0", attrs: { flat: "" } },
                                _vm._l(_vm.route, function (stop, i) {
                                  return _c(
                                    "v-list-item",
                                    { key: i, staticClass: "pl-0 py-0" },
                                    [
                                      _c(
                                        "v-list-item-icon",
                                        [
                                          _c("v-icon", [
                                            _vm._v(_vm._s(stop.icon)),
                                          ]),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-list-item-content",
                                        [
                                          _c("v-list-item-title", [
                                            _vm._v(_vm._s(stop.name)),
                                          ]),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("v-divider", { attrs: { vertical: "" } }),
                  _c(
                    "v-col",
                    { staticClass: "pl-4", attrs: { cols: "auto", md: "2" } },
                    [
                      _c(
                        "div",
                        { staticClass: "text-overline font-weight-bold" },
                        [_vm._v("Vehicle Type")]
                      ),
                      _c("div", { staticClass: "text-subtitle-1" }, [
                        _vm._v(_vm._s(_vm.vehicleType)),
                      ]),
                      _c(
                        "div",
                        { staticClass: "text-overline font-weight-bold" },
                        [_vm._v("Trip Type")]
                      ),
                      _c("div", { staticClass: "text-subtitle-1" }, [
                        _vm._v(_vm._s(_vm.tripType)),
                      ]),
                      _c(
                        "div",
                        { staticClass: "text-overline font-weight-bold" },
                        [_vm._v("Trip Event(s)")]
                      ),
                      _c("div", { staticClass: "text-subtitle-1" }, [
                        _vm._v(_vm._s(_vm.tripEvents)),
                      ]),
                      _c(
                        "div",
                        { staticClass: "text-overline font-weight-bold" },
                        [_vm._v("Bid ID")]
                      ),
                      _c("div", { staticClass: "text-subtitle-1" }, [
                        _vm._v("-"),
                      ]),
                      _c(
                        "div",
                        { staticClass: "text-overline font-weight-bold" },
                        [_vm._v("Vehicle Notes")]
                      ),
                      _c(
                        "div",
                        { staticClass: "text-subtitle-1" },
                        [
                          _c(
                            "v-text-field",
                            _vm._b(
                              {
                                attrs: {
                                  type: "text",
                                  maxlength: "255",
                                  hint: "Press enter to save vehicle notes",
                                },
                                on: {
                                  keyup: function ($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "enter",
                                        13,
                                        $event.key,
                                        "Enter"
                                      )
                                    )
                                      return null
                                    return _vm.handleSaveVehicleNotes.apply(
                                      null,
                                      arguments
                                    )
                                  },
                                },
                                model: {
                                  value: _vm.vehicleNotes,
                                  callback: function ($$v) {
                                    _vm.vehicleNotes = $$v
                                  },
                                  expression: "vehicleNotes",
                                },
                              },
                              "v-text-field",
                              _vm.inputProps,
                              false
                            )
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                  _c("v-divider", { attrs: { vertical: "" } }),
                  _c(
                    "v-col",
                    { staticClass: "pl-4", attrs: { cols: "auto", md: "2" } },
                    [
                      _c(
                        "div",
                        { staticClass: "text-overline font-weight-bold" },
                        [_vm._v("Wheelchair Lift")]
                      ),
                      _c("div", { staticClass: "text-subtitle-1" }, [
                        _vm._v(
                          _vm._s(
                            _vm.assignment.numWheelchairSlot > 0 ? "Yes" : "No"
                          )
                        ),
                      ]),
                      _c(
                        "div",
                        { staticClass: "text-overline font-weight-bold" },
                        [_vm._v("Special Equipment")]
                      ),
                      _c("div", { staticClass: "text-subtitle-1" }, [
                        _vm._v(_vm._s(_vm.specEq)),
                      ]),
                      _c(
                        "div",
                        { staticClass: "text-overline font-weight-bold" },
                        [_vm._v("Special Indicators")]
                      ),
                      _c("div", { staticClass: "text-subtitle-1" }, [
                        _vm._v(_vm._s(_vm.specIns)),
                      ]),
                      _c(
                        "div",
                        { staticClass: "text-overline font-weight-bold" },
                        [_vm._v("Zone")]
                      ),
                      _c("div", { staticClass: "text-subtitle-1" }, [
                        _vm._v(_vm._s(_vm.zone)),
                      ]),
                    ]
                  ),
                  _c("v-divider", { attrs: { vertical: "" } }),
                  _c(
                    "v-col",
                    { staticClass: "pl-4", attrs: { cols: "auto", md: "1" } },
                    [
                      _c(
                        "div",
                        { staticClass: "text-overline font-weight-bold" },
                        [_vm._v("# Adults / Students")]
                      ),
                      _c("div", { staticClass: "text-subtitle-1" }, [
                        _vm._v(
                          _vm._s(_vm.assignment.totalAdults) +
                            " / " +
                            _vm._s(_vm.assignment.totalStudents)
                        ),
                      ]),
                      _c(
                        "div",
                        { staticClass: "text-overline font-weight-bold" },
                        [_vm._v("Grade(s)")]
                      ),
                      _c("div", { staticClass: "text-subtitle-1" }, [
                        _vm._v(_vm._s(_vm.grades)),
                      ]),
                    ]
                  ),
                  _c("v-divider", { attrs: { vertical: "" } }),
                  _c(
                    "v-col",
                    { staticClass: "pl-4", attrs: { cols: "auto", md: "3" } },
                    [
                      _c(
                        "v-row",
                        { attrs: { dense: "" } },
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "6" } },
                            [
                              _c("time-picker", {
                                attrs: {
                                  disabled: _vm.activeFab == 0,
                                  dense: "",
                                  label: "Start Time",
                                  outlined: "",
                                },
                                model: {
                                  value: _vm.enterMileTime.startTime,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.enterMileTime,
                                      "startTime",
                                      $$v
                                    )
                                  },
                                  expression: "enterMileTime.startTime",
                                },
                              }),
                              _c("time-picker", {
                                attrs: {
                                  disabled: _vm.activeFab == 0,
                                  dense: "",
                                  label: "End Time",
                                  outlined: "",
                                },
                                model: {
                                  value: _vm.enterMileTime.endTime,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.enterMileTime, "endTime", $$v)
                                  },
                                  expression: "enterMileTime.endTime",
                                },
                              }),
                              _c("v-text-field", {
                                attrs: {
                                  disabled: _vm.activeFab == 0,
                                  min: 0,
                                  dense: "",
                                  label: "Start Odometer",
                                  outlined: "",
                                  "prepend-icon": "mdi-bus",
                                  type: "number",
                                },
                                model: {
                                  value: _vm.enterMileTime.startMileage,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.enterMileTime,
                                      "startMileage",
                                      $$v
                                    )
                                  },
                                  expression: "enterMileTime.startMileage",
                                },
                              }),
                              _c("v-text-field", {
                                attrs: {
                                  disabled: _vm.activeFab == 0,
                                  min: 0,
                                  dense: "",
                                  label: "End Odometer",
                                  outlined: "",
                                  "prepend-icon": "mdi-bus",
                                  type: "number",
                                },
                                model: {
                                  value: _vm.enterMileTime.endMileage,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.enterMileTime,
                                      "endMileage",
                                      $$v
                                    )
                                  },
                                  expression: "enterMileTime.endMileage",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { staticClass: "pl-4", attrs: { cols: "4" } },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "text-overline font-weight-bold",
                                },
                                [_vm._v("Total Time")]
                              ),
                              _c("div", { staticClass: "text-subtitle-1" }, [
                                _vm._v(_vm._s(_vm.calculatedTime)),
                              ]),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "text-overline font-weight-bold mt-16",
                                },
                                [_vm._v("Total Mileage")]
                              ),
                              _c("div", { staticClass: "text-subtitle-1" }, [
                                _vm._v(_vm._s(_vm.calculatedMileage)),
                              ]),
                            ]
                          ),
                        ],
                        1
                      ),
                      _vm.expand
                        ? _c(
                            "v-fab-transition",
                            [
                              _vm.canEnterMileage
                                ? _c(
                                    "v-btn",
                                    {
                                      key: _vm.fabs[_vm.activeFab].icon,
                                      staticClass: "fab-edit-save",
                                      attrs: {
                                        color: _vm.fabs[_vm.activeFab].color,
                                        disabled:
                                          _vm.hasTimeOrMileageError ||
                                          _vm.saving ||
                                          _vm.invoiceReady,
                                        loading: _vm.saving,
                                        bottom: "",
                                        dark: "",
                                        fab: "",
                                        right: "",
                                        small: "",
                                      },
                                      on: { click: _vm.handleEnterMileTime },
                                    },
                                    [
                                      _c("v-icon", [
                                        _vm._v(
                                          _vm._s(_vm.fabs[_vm.activeFab].icon)
                                        ),
                                      ]),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }